import { Typography, TypographyProps } from "@mui/material";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const StyledTypography = styled(Typography)<{ card_title?: boolean }>`
  font-size: ${({ card_title }) => (card_title ? "30px" : "32px")};

  @media (max-width: 600px) {
    font-size: ${({ card_title }) => (card_title ? "20px" : "24px")};
  }
`;

interface PageTitleProps extends PropsWithChildren<TypographyProps> {
  cardTitle?: boolean;
}

const PageTitle = ({ children, cardTitle, ...props }: PageTitleProps) => {
  return (
    <StyledTypography fontWeight={600} {...props}>
      {children}
    </StyledTypography>
  );
};

export default PageTitle;
