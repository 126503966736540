import { lazy } from "react";
import { Navigate } from "react-router-dom";
import LayoutSuspense from "./shared/LayoutSuspense";
import ModuleSuspense from "./shared/ModuleSuspense";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Auth components
import SignIn from "pages/auth";

// Profile
const EmployeeClaims = lazy(() => import("./pages/modules/Profile/Claims"));
const EmployeeSalaryAdvance = lazy(
  () => import("./pages/modules/Profile/SalaryAdvance")
);
const Employee401kSubscriptions = lazy(
  () => import("./pages/modules/Profile/401K")
);
const MyProfile = lazy(() => import("./pages/modules/Profile/my-profile"));
const EmployeeOvertime = lazy(() => import("./pages/modules/Profile/Overtime"));
const EmployeeWageGarnishment = lazy(
  () => import("./pages/modules/Profile/WageGarnishment")
);
const EmployeeStandingOrder = lazy(
  () => import("./pages/modules/Profile/StandingOrder")
);

const SelfServiceLoanApplication = lazy(
  () => import("./pages/modules/Profile/LoanApplication")
);
const SelfServiceLoanApplicationRepaymentHistory = lazy((
  () => import("./pages/modules/Profile/LoanApplication/repaymentsHistory")
));

// Dashboard components
const Dashboard = lazy(() => import("./pages/modules/Dashboard"));

// Team
const Employees = lazy(() => import("./pages/modules/Team/Employees"));
const EmployeeView = lazy(
  () => import("./pages/modules/Team/Employees/Profile")
);

// Manager
const ManagerOvertime = lazy(() => import("./pages/modules/Manager/Overtime"))
const ManagerClaim = lazy(() => import("./pages/modules/Manager/claims"));

// Payroll
const Schedule = lazy(() => import("./pages/modules/Payroll/schedule"));
const ScheduleConfiguration = lazy(
  () =>
    import("./pages/modules/Payroll/schedule/components/pay-schedule/config")
);
const TaxSystemConfig = lazy(
  () =>
    import(
      "./pages/modules/Payroll/schedule/components/pay-schedule/config/components/tax-system"
    )
);
const PayItemsConfig = lazy(
  () =>
    import(
      "./pages/modules/Payroll/schedule/components/pay-schedule/config/components/pay-items"
    )
);
const MixinsConfig = lazy(
  () =>
    import(
      "./pages/modules/Payroll/schedule/components/pay-schedule/config/components/mixins"
    )
);
const EmployeesConfig = lazy(
  () =>
    import(
      "./pages/modules/Payroll/schedule/components/pay-schedule/config/components/employees"
    )
);
const PayrollProcessDetails = lazy(
  () =>
    import("./pages/modules/Payroll/schedule/components/history/details/full")
);
const EmployeePayrollDetails = lazy(
  () =>
    import(
      "./pages/modules/Payroll/schedule/components/history/details/individual"
    )
);

// Mixins
const Bonus = lazy(() => import("./pages/modules/Payroll/Bonus"));
const Claims = lazy(() => import("./pages/modules/Payroll/Claims"));
const Overtime = lazy(() => import("./pages/modules/Payroll/Overtime"));
const K401Subscriptions = lazy(() => import("./pages/modules/Payroll/401K"));
const Deduction = lazy(() => import("./pages/modules/Payroll/Deduction"));
const SalaryAdvance = lazy(
  () => import("./pages/modules/Payroll/SalaryAdvance")
);
const StandingOrder = lazy(
  () => import("./pages/modules/Payroll/StandingOrder")
);
const WageGarnishment = lazy(
  () => import("./pages/modules/Payroll/WageGarnishment")
);
const LoanApplication = lazy(
  () => import("./pages/modules/Payroll/LoanApplication")
);

// Reports
const Reports = lazy(() => import("./pages/modules/reports"));

// Setting./pages/modules/Payroll/WageGarnishment
const Settings = lazy(() => import("./pages/modules/Settings"));

export const authenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <DashboardLayout children={null} />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/dashboard",
        element: (
          <ModuleSuspense>
            <Dashboard />
          </ModuleSuspense>
        ),
      },
      { path: "*", element: <Navigate to="/dashboard" /> },
      { path: "/", element: <Navigate to="/dashboard" /> },
    ],
  },
  {
    path: "/profile",
    element: (
      <LayoutSuspense>
        <DashboardLayout children={null} />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "salary-advance",
        element: (
          <ModuleSuspense>
            <EmployeeSalaryAdvance />
          </ModuleSuspense>
        ),
      },
      {
        path: "overtime",
        element: (
          <ModuleSuspense>
            <EmployeeOvertime />
          </ModuleSuspense>
        ),
      },
      {
        path: "claims",
        element: (
          <ModuleSuspense>
            <EmployeeClaims />
          </ModuleSuspense>
        ),
      },
      {
        path: "my-profile",
        element: (
          <ModuleSuspense>
            <MyProfile />
          </ModuleSuspense>
        ),
      },
      {
        path: "401K",
        element: (
          <ModuleSuspense>
            <Employee401kSubscriptions />
          </ModuleSuspense>
        ),
      },
      {
        path: "wage-garnishment",
        element: (
          <ModuleSuspense>
            <EmployeeWageGarnishment />
          </ModuleSuspense>
        ),
      },
      {
        path: "standing-order",
        element: (
          <ModuleSuspense>
            <EmployeeStandingOrder />
          </ModuleSuspense>
        ),
      },
      {
        path: "loan-application",
        element: (
          <ModuleSuspense>
            <SelfServiceLoanApplication />
          </ModuleSuspense>
        ),
      },
      {
        path: "/profile/loan-application/:id/history",
        element: (
          <ModuleSuspense>
            <SelfServiceLoanApplicationRepaymentHistory />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/team",
    element: (
      <LayoutSuspense>
        <DashboardLayout children={null} />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "employees",
        element: (
          <ModuleSuspense>
            <Employees />
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/employees/:id",
        element: (
          <ModuleSuspense>
            <EmployeeView />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "payroll",
    element: (
      <LayoutSuspense>
        <DashboardLayout children={null} />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "pay-schedules",
        element: (
          <ModuleSuspense>
            <Schedule />
          </ModuleSuspense>
        ),
      },
      {
        path: "pay-schedules/runs/:id/details",
        element: (
          <ModuleSuspense>
            <PayrollProcessDetails />
          </ModuleSuspense>
        ),
      },
      {
        path: "pay-schedules/runs/details/employee/:emp_id",
        element: (
          <ModuleSuspense>
            <EmployeePayrollDetails />
          </ModuleSuspense>
        ),
      },
      {
        path: "pay-schedules/:schedule_id",
        element: (
          <ModuleSuspense>
            <ScheduleConfiguration />
          </ModuleSuspense>
        ),
      },
      {
        path: "pay-schedules/:schedule_id/tax-system",
        element: (
          <ModuleSuspense>
            <TaxSystemConfig />
          </ModuleSuspense>
        ),
      },
      {
        path: "pay-schedules/:schedule_id/pay-items",
        element: (
          <ModuleSuspense>
            <PayItemsConfig />
          </ModuleSuspense>
        ),
      },
      {
        path: "pay-schedules/:schedule_id/mixins",
        element: (
          <ModuleSuspense>
            <MixinsConfig />
          </ModuleSuspense>
        ),
      },
      {
        path: "pay-schedules/:schedule_id/employees",
        element: (
          <ModuleSuspense>
            <EmployeesConfig />
          </ModuleSuspense>
        ),
      },

      {
        path: "claims",
        element: (
          <ModuleSuspense>
            <Claims />
          </ModuleSuspense>
        ),
      },
      {
        path: "overtime",
        element: (
          <ModuleSuspense>
            <Overtime />
          </ModuleSuspense>
        ),
      },
      {
        path: "bonus",
        element: (
          <ModuleSuspense>
            <Bonus />
          </ModuleSuspense>
        ),
      },
      {
        path: "deductions",
        element: (
          <ModuleSuspense>
            <Deduction />
          </ModuleSuspense>
        ),
      },
      {
        path: "salary-advance",
        element: (
          <ModuleSuspense>
            <SalaryAdvance />
          </ModuleSuspense>
        ),
      },
      {
        path: "wage-garnishments",
        element: (
          <ModuleSuspense>
            <WageGarnishment />
          </ModuleSuspense>
        ),
      },
      {
        path: "401k-subscriptions",
        element: (
          <ModuleSuspense>
            <K401Subscriptions />
          </ModuleSuspense>
        ),
      },
      {
        path: "standing-orders",
        element: (
          <ModuleSuspense>
            <StandingOrder />
          </ModuleSuspense>
        ),
      },
      {
        path: "loan-application",
        element: (
          <ModuleSuspense>
            <LoanApplication />
          </ModuleSuspense>
        ),
      },
    ],
  },

  {
    path: "settings",
    element: (
      <LayoutSuspense>
        <DashboardLayout children={null} />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "",
        element: (
          <ModuleSuspense>
            <Settings />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "reports",
    element: (
      <LayoutSuspense>
        <DashboardLayout children={null} />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "",
        element: (
          <ModuleSuspense>
            <Reports />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/manager",
    element: (
      <LayoutSuspense>
        <DashboardLayout children={null} />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "claims",
        element: (
          <ModuleSuspense>
            <ManagerClaim />
          </ModuleSuspense>
        ),
      },
      {
        path: "overtime",
        element: (
          <ModuleSuspense>
            <ManagerOvertime />
          </ModuleSuspense>
        ),
      },
    ],
  },
];

export const unauthenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <AuthLayout children={null} />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/sign-in",
        element: (
          <ModuleSuspense>
            <SignIn />
          </ModuleSuspense>
        ),
      },
      {
        path: "/",
        element: <Navigate to="/sign-in" />,
      },
      {
        path: "*",
        element: <Navigate to="/sign-in" />,
      },
    ],
  },
];
