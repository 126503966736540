import React from "react";
import styled from "styled-components";

import {
  TableCell,
  tableCellClasses,
  TableHead as THead,
  TableRow as MuiTableRow,
  TableSortLabel,
} from "@mui/material";

import { UnfoldMore } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EDEEF1",
    color: "#101828",
    borderColor: " #D7DAE0",
    fontSize: "0.8rem",
  },
}));

const TableRow = styled(MuiTableRow)`
  && {
    border: none;
  }
`;

const TableHeader = (props) => {
  const { headCells = [], order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <THead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ p: 4 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                display: headCell.id === "options" ? "none" : "",
              }}
              IconComponent={UnfoldMore}
            >
              {headCell.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </THead>
  );
};

export default TableHeader;
